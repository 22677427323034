@import "~react-image-gallery/styles/css/image-gallery.css";

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.image-gallery-slide-wrapper,
.image-gallery-thumbnail {

    border-radius: 10px;
    overflow: hidden;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 58px !important;

}

.image-gallery {
    min-width: 200px;
    max-width: 500px;
}

@keyframes scale-up {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes flashy-text {
    0% {
        color: #ff0000;
        /* Red */
        text-shadow: 0 0 10px #ff0000;
    }

    50% {
        color: #000000;
        /* Green */
        text-shadow: 0 0 10px #000000;
    }

    100% {
        color: #ff0000;
        /* Blue */
        text-shadow: 0 0 10px #ff0000;
    }
}

@keyframes flashy-colors {
    0% {
        background-color: #ffffff;
    }

    50% {
        background-color: #e7f975;
    }

    100% {
        background-color: #ffffff;
    }
}